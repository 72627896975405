<template>
	<section>
		<base-section class="px-12" style="background: transparent">
			<v-img
				:src="require('@/assets/images/sdc_logo_portrait.png')"
				class="mx-auto mb-3"
				contain
				max-height="96"
			/>
			<base-section-heading :title="$t('solarDecathlonChina')">
				Solar Decathlon® conceived in China in 2011 as an achievement of
				the Sino-U.S. Strategic and Economic Dialogues signed by the
				U.S. and Chinese governments.
			</base-section-heading>

			<v-row align="center" justify="center">
				<v-col cols="5">
					<v-card shaped>
						<v-img
							:src="
								require('@/assets/images/about/sdc2018-champion.png')
							"
						/>
					</v-card>
				</v-col>

				<v-col cols="7">
					<p>
						As an achievement of the Seventh Sino-U.S. Strategic and
						Economic Dialogue, Solar Decathlon China (SDC) is
						co-sponsored by the U.S. Department of Energy and China
						National Energy Administration, and organized by China
						Overseas Development Association. The City of
						<a href="/archives/2013">Datong</a> in Shanxi Province
						witnessed its successful inauguration in 2013 with the
						participation of 21 teams from 13 countries. The
						competition received support from more than 30
						governmental agencies and 200 enterprises and attracted
						approximately 260,000 visitors. In 2018, the City of
						<a href="/archives/2018">Dezhou</a> in Shandong Province
						successfully hosted the second SDC event. 19 teams from
						34 universities participated, 370 enterprises and
						500,000 visitors involved. The third SDC event was held
						in 2022 in the City of
						<a href="/archives/2022">Zhangjiakou</a>, Hebei
						Province. 15 teams consisting of 29 schools from 10
						countries were qualified for the final. Over 180 enterprises and
						organizations participated.
					</p>
					<p>
						The objective of Solar Decathlon China is to create a
						workforce development and education program which can
						provide student architects, engineers, business majors,
						and communicators the opportunity to cooperate in
						designing and building sustainable housing projects.
					</p>
				</v-col>
			</v-row>
		</base-section>

		<v-img
			height="480"
			:src="require('@/assets/images/about/sdc2018-team1.png')"
		/>

		<!-- Mission -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('missions')">
				Solar Decathlon China aims to create a workforce development and
				education program for future generations, and seeks to reach its
				impacts on multiple scales.
			</base-section-heading>

			<v-row>
				<v-col v-for="(mission, i) in missions" :key="i" cols="6">
					<base-avatar-card v-bind="mission" align="left" horizontal>
						{{ mission.text }}
					</base-avatar-card>
				</v-col>
			</v-row>
		</base-section>

		<v-img
			height="480"
			:src="require('@/assets/images/about/sdc2018-site3.png')"
		/>

		<!-- History -->
		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('history')">
				Solar Decathlon China inaugurated its first event in 2013, and concecutively occurred in 2018, and 2022.
			</base-section-heading>

			<v-timeline>
				<v-timeline-item
					v-for="(year, i) in years"
					:key="i"
					:color="year.color"
				>
					<template v-slot:opposite>
						<span
							:class="`headline font-weight-light ${year.color}--text`"
							v-text="year.year"
						></span>
					</template>
					<h3 :class="`custom-title mb-3 ${year.color}--text`">
						{{ year.title }}
					</h3>
					<p>
						{{ year.text }}
					</p>
					<v-btn small rounded :color="year.color" :to="year.url">
						{{ $t("discoverMore") }}
					</v-btn>
				</v-timeline-item>
			</v-timeline>
		</base-section>
	</section>
</template>

<script>
export default {
	data: () => ({
		missions: [
			{
				title: "Talent Cultivation",
				icon: "mdi-brain",
				text: "Committing to talent cultivation by offering students a unique opportunity for learning and putting theory into practice. The projects are developed by multidisciplinary teams, allowing the students the opportunity to learn about technical issues and teamwork, communication skills, and socio-economic issues.",
				color: "warning",
				dark: true,
			},
			{
				title: "Public Awareness",
				icon: "mdi-account-group",
				text: "Creating a platform to educate the general public about renewable energy, high-performance buildings, and a low-carbon lifestyle and raise people’s awareness about energy saving and environmental protection.",
				color: "primary",
				dark: true,
			},
			{
				title: "Industry-Education-Research",
				icon: "mdi-state-machine",
				text: "Leveraging industry-education-research cooperation and stimulating industries to evolve. It advocates businesses and enterprises that focus on efficiency and sustainability and facilitates industry upgrade and transformation.",
				color: "success",
				dark: true,
			},
			{
				title: "Regional Development",
				icon: "mdi-home-group",
				text: "Forming a legacy for the host city. The SDC serves as a paradigm for sustainable urbanization, as well as a pioneer actively exploring paths for high-quality regional development.",
				color: "error",
				dark: true,
			},
		],
		years: [
			{
				year: "2022",
				title: "Zhangjiakou",
				text: "Despite of the postponement, the third Solar Decathlon China event was successfully held in 2022, overcoming the global epidemic of Covid-19, in Zhangjiakou, Hebei Province.",
				url: "/archives/2022",
				color: "primary",
			},
			{
				title: "Dezhou",
				year: "2018",
				text: "Solar Decathlon China 2018 was held in Dezhou, Shandong Province. 17 competition teams and 2 exhibition teams participated. Team SCUT-POLITO took the crown in the final.",
				url: "/archives/2018",
				color: "warning",
			},
			{
				title: "Datong",
				year: "2013",
				text: "The first Solar Decathlon China event was inaugurated in 2013 in Datong, Shanxi Province. 22 teams participated and 19 competition prototypes were assembled. Team UOW claimed the title in the final.",
				url: "/archives/2013",
				color: "success",
			},
		],
	}),
};
</script>

<style scoped>
.custom-title {
	font-weight: 600;
	letter-spacing: 0.0892857143em;
	text-transform: uppercase;
}
</style>