<template>
	<section>
		<base-section class="px-12" style="background: transparent">
			<v-img
				:src="require('@/assets/images/about/logo-sd-us.png')"
				class="mx-auto mb-3"
				contain
				max-height="84"
			/>
			<base-section-heading :title="$t('solarDecathlon')">
				The U.S. Department of Energy Solar Decathlon® is a collegiate
				competition, comprising 10 contests, that challenges student
				teams to design and build highly efficient and innovative
				buildings powered by renewable energy.
			</base-section-heading>

			<v-row align="center" justify="center">
				<v-col cols="5">
					<v-card shaped>
						<v-img :src="require('@/assets/images/about/sd-1.jpg')" />
					</v-card>
				</v-col>

				<v-col cols="7">
					<p>
						The U.S. Department of Energy Solar Decathlon® is a
						collegiate competition that prepares the next generation
						of building professionals to design and build
						high-performance, low-carbon buildings powered by
						renewables. The Design Challenge is a one- to
						two-semester, design-only competition, while the Build
						Challenge is a two-year design-build competition.
					</p>

					<p>
						Celebrating its 20th anniversary in 2022, the Solar
						Decathlon has challenged more than 25,000 students to
						create efficient, affordable buildings powered by
						renewables, while promoting student innovation, STEM
						education, and workforce development opportunities in
						the buildings industry.
					</p>

					<p>
						The first Solar Decathlon Build Challenge was held in
						2002. The Build Challenge occurred biennially in 2005,
						2007, 2009, 2011, 2013, 2015, 2017, and 2020. Race to
						Zero competition joined the Solar Decathlon in 2019 as
						the Design Challenge. The Design Challenge occurred
						annually in 2019, 2020, and 2021.
					</p>
					<v-btn
						color="primary"
						href="https://www.solardecathlon.gov/"
						target="_blank"
					>
						{{ $t("discoverMore") }}
						<v-icon right> mdi-open-in-new </v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</base-section>

		<base-section class="px-12" style="background: transparent">
			<base-section-heading :title="$t('international')">
				Since the first competition in 2002, the Solar Decathlon has
				expanded internationally to include six additional worldwide
				competitions.
			</base-section-heading>

			<v-slide-group>
				<template v-for="(logo, i) in logos">
					<v-slide-item :key="i" class="align-self-center">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<a :href="logo.url" target="_blank">
									<base-img
										:src="
											require(`@/assets/images/about/logo-sd-${logo.suffix}.png`)
										"
										contain
										height="60"
										width="120"
										v-bind="attrs"
										v-on="on"
									/>
								</a>
							</template>
							<span>{{ logo.name }}</span>
						</v-tooltip>
					</v-slide-item>

					<v-responsive
						v-if="i < 5"
						:key="`divider-${i}`"
						class="text-center"
						height="60"
					>
						<v-divider vertical />
					</v-responsive>
				</template>
			</v-slide-group>
		</base-section>
	</section>
</template>

<script>
export default {
	data: () => ({
		logos: [
			{
				suffix: "africa",
				name: "Africa",
				url: "https://www.solardecathlonafrica.com/",
			},
			{
				suffix: "china",
				name: "China",
				url: "https://sdchina.org.cn/",
			},
			{
				suffix: "europe",
				name: "Europe",
				url: "https://solardecathlon.eu/",
			},
			{
				suffix: "india",
				name: "India",
				url: "https://solardecathlonindia.in/",
			},
			{
				suffix: "lac",
				name: "Latin America and Caribbean",
				url: "https://solardecathlonlac.com/",
			},
			{
				suffix: "me",
				name: "Middle East",
				url: "https://www.solardecathlonme.com/",
			},
		],
	}),
};
</script>