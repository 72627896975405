<template>
	<v-container>
		<v-row>
			<v-col cols="2">
				<v-sheet
					class="mx-auto"
					rounded="lg"
					style="position: sticky; top: 76px"
				>
					<v-list color="transparent">
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title
									class="
										text-h6
										font-weight-light
										text-uppercase
									"
									>{{ $t("about") }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>

						<v-divider class="mt-1"></v-divider>

						<v-list-item-group color="primary">
							<v-list-item
								:class="
									pageShow == 0
										? 'v-item--active v-list-item--active'
										: ''
								"
								class="text-button"
								to="/about/0"
								@click="pageShow = 0"
							>
								{{ $t("solarDecathlon") }}
							</v-list-item>
							<v-list-item
								:class="
									pageShow == 1
										? 'v-item--active v-list-item--active'
										: ''
								"
								to="/about/1"
								class="text-button"
								@click="pageShow = 1"
							>
								{{ $t("solarDecathlonChina") }}
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-sheet>
			</v-col>

			<v-col cols="10">
				<v-sheet min-height="70vh" rounded="lg">
					<solar-decathlon v-if="pageShow == 0"></solar-decathlon>
					<solar-decathlon-china v-else></solar-decathlon-china>
				</v-sheet>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import SolarDecathlon from "@/views/about/sections/SolarDecathlon.vue";
import SolarDecathlonChina from "@/views/about/sections/SolarDecathlonChina.vue";
import router from "@/router/index.js";

export default {
	name: "About",

	components: {
		SolarDecathlon,
		SolarDecathlonChina,
	},
	data() {
		return {
			pageShow: 0,
		};
	},
	mounted() {
		const pageShow = router.currentRoute.params.pageShow;
		this.pageShow = parseInt(pageShow || 0);
	},
};
</script>
